
<template>
    <div class="prewImg-wrap" v-show="show">
        <div class="mask" ></div>

        <div class="img-wrap" @click.self = 'close'>
            <img  ref="img" :src="url" alt="" class="pic"
                 @load="handleImgLoad"
                 @error="handleImgError">
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            show: false,
            loading:false,
            url:""
        }
    },
    mounted(){
    },
    watch:{
        url(val) {
            this.$nextTick(_ => {
                const $img = this.$refs.img;
                if (!$img.complete) {
                    this.loading = true;
                }
            });
        }
    },
    methods:{
        close(){
            this.show=false
        },
        handleImgLoad(){
            this.loading=false
        },
        handleImgError(e){
            this.loading=false
        }
    }

}
</script>
<style scoped>

.prewImg-wrap{
    position:fixed;
    left:0;
    top:0;
    width: 100%;
    height: 100%;;
    overflow: hidden;
    z-index:2000;
}

.ic{
    display: inline-block;
    margin-right: 10px;
}
.mask{
    background: rgba(0,0,0,0.6);
    position: absolute;
    top:0;
    left:0;
    z-index: 10;
    width: 100%;
    height: 100%;
}
.img-wrap{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index:20;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pic{
    max-height: 100%;
    max-width: 80%;
}

</style>