<template>
    <div class = 'footerBox'>
        <div>Copyright © 2021-2022 oddboy All Rights Reserved</div>
        <div style = 'line-height: 36px;'><a href = 'https://beian.miit.gov.cn/' target = '_blank'>豫ICP备2020034857号-1</a></div>
    </div>
</template>

<script>
export default {
    name: "BlogFooter"
}
</script>

<style scoped lang="less">
    .footerBox{
        padding: 30px 0 10px;
        text-align: center;
        a{
            &:hover{
                color:#4084ff;
            }
        }
    }
</style>