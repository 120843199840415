<template>
  <div id='box'>
    <div id="app" v-cloak>
      <BlogHead></BlogHead>
      <div style="min-height: calc(100vh - 165px);">
        <transition name="slide-fade">
          <router-view></router-view>
        </transition>
      </div>

      <!--鼠标点击效果-->
      <ClickStyle />
      <BackTop  color='#4084ff'></BackTop>
      <BlogFooter></BlogFooter>

    </div>
  </div>
</template>
<script>
  import ClickStyle from "./components/clickStyle";
  import BackTop from "@/components/backTop/index.vue";
  import BlogHead from '@/components/header/index.vue'
  import BlogFooter from '@/components/footer/index.vue'
  export default {
    name: "App",
    data: function() {
      return {
        open: false,
        html: "",
      };
    },
    components: {
      ClickStyle,
      BackTop,
      BlogHead,
      BlogFooter
    },
    created() {
    },
    mounted() {
      this.widthChange()

    },
    methods: {
      initTheme() {
        let theme = localStorage.blogTheme || (window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" :
          "light")
        this.changeTheme(theme)
        this.themeEvent()
      },
      changeTheme(type) {
        this.$store.commit('setTheme', type)
        localStorage.blogTheme = type;
        document.documentElement.className = type
      },
      themeEvent() {
        window.matchMedia('(prefers-color-scheme: dark)').addListener((ele) => {
          let theme = ele.matches ? "dark" : "light"
          this.changeTheme(theme)
        })

      },
      widthChange() {
        var _this = this;
        (function(doc, win) {
          var docEl = doc.documentElement,
            resizeEvt =
            "orientationchange" in window ? "orientationchange" : "resize",
            recalc = function() {
              var clientWidth = docEl.clientWidth;
              if (!clientWidth) return;
              //固定宽度750px 基准像素100px
              _this.$store.commit("widthResize", clientWidth);
            };

          if (!doc.addEventListener) return;
          win.addEventListener(resizeEvt, recalc, false);
          doc.addEventListener("DOMContentLoaded", recalc, false);
        })(document, window);
      },
    },
  };
</script>

<style lang="less">
  @font-face {
    font-family: "blogFont";
    src: url(http://oss.oddboy.top/public/20220929/font.otf)
  }
  

  #app {
    font-family: blogFont, Avenir, Helvetica, Arial, sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-top: 60px;
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto;

    .vc-container,
    .aplayer .aplayer-music,
    .aplayer .aplayer-list {
      font-family: blogFont;
    }
  }

  .slide-fade-enter-active {
    transition: all .3s ease;
  }

  .slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter,
  .slide-fade-leave-to

  /* .slide-fade-leave-active for below version 2.1.8 */
    {
    transform: translateX(10px);
    opacity: 0;
  }
</style>
