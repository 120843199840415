import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store'
import MuseUI from 'muse-ui'
import 'muse-ui/dist/muse-ui.css'
// import 'highlight.js/styles/github.css';
import prevImg from "./components/prevImg"
Vue.use(prevImg)
//时钟组件
// import vueClock from 'vue-clock-lonlyape'
// Vue.use(vueClock) 
import VCalendar from 'v-calendar'
Vue.use(VCalendar,{
  componentPrefix:'vc'
})
import './config/components'
//日历组件


MuseUI.theme.add('myTheme', {
  primary: MuseUI.Colors.blue,
  secondary: MuseUI.Colors.pinkA200,
  success: MuseUI.Colors.green,
  warning: MuseUI.Colors.yellow600,
  info: MuseUI.Colors.blue,
  error: MuseUI.Colors.red,
}).use('myTheme');
Vue.use(MuseUI)

import md5 from 'crypto-js/md5'
Vue.prototype.$md5 = md5
Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
