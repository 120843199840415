// import { resolve } from 'core-js/fn/promise'
import Vue from 'vue'
import VueRouter from 'vue-router'

//引入页面加载进度
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
// 配置隐藏右侧加载动画
NProgress.configure({ showSpinner: false });
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
}
Vue.use(VueRouter)

const router = new VueRouter({
    mode:'hash',
    routes:[
        {
            path:'/',
            name:'home',
            meta:{},
            redirect:'/index',
            component: (resolve) => require(['@/view/main'], resolve),
            children:[
                {
                    path:'index',
                    name:'index',
                    meta:{},
                    component: (resolve) => require(['@/view/markdownDetail/index.vue'], resolve), 
                },
                {
                    path:'/detail/:id',
                    name:'detail',
                    meta:{},
                    component: (resolve) => require(['@/view/markdownDetail/detail.vue'], resolve),
                }
            ]
        },
        {
            path:'/game',
            name:'game',
            meta:{title:'MarkDown 常用语句'},
            component: (resolve) => require(['@/components/HelloWorld.vue'], resolve),
        },
        {
            path:'/resize-box',
            name:'demo',
            meta:{title:'demo'},
            component: (resolve) => require(['@/view/demo/resizeBoxDemo/index.vue'], resolve),
        },
        {
          path:'*',
          name:'404',
          meta:{title:'404'},
          component: (resolve) => require(['@/components/404.vue'], resolve),
        }

    ],
    scrollBehavior () {
        // vue2.0  x  y  控制
        // vue3.0  left  top 控制
        return { x: 0, y: 0 }
      }
})

router.beforeEach((to, from, next) => {
    NProgress.start()
    if (true) {
        window.document.title = to.meta.title||"风来过丶凌乱了悲伤的it技术分享"
        
        next()
      /* has token*/
    //   if (to.path === '/login') {
    //     next({ path: '/' })
    //     NProgress.done()
    //   } else {
    //     if (store.getters.roles.length === 0) {
    //       // 判断当前用户是否已拉取完user_info信息
    //       store.dispatch('GetInfo').then(() => {
    //         store.dispatch('GenerateRoutes').then(accessRoutes => {
    //           // 根据roles权限生成可访问的路由表
    //           router.addRoutes(accessRoutes) // 动态添加可访问路由表
    //           next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
    //         })
    //       }).catch(err => {
    //           store.dispatch('LogOut').then(() => {
    //             Message.error(err)
    //             next({ path: '/' })
    //           })
    //         })
    //     } else {
    //       next()
    //     }
    //   }
    } else {
        next()

    }
  })
  
  router.afterEach(() => {
        NProgress.done()
      
  })

export default router