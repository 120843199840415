import PrevView from "./prevImg.vue"
export default {

    install(Vue,options){
        let PrevVueConstructor= Vue.extend(PrevView)
        let instance= new PrevVueConstructor()
        document.body.appendChild(instance.$mount().$el);

        let prevObj={
            show(opts={text:"",url:""}){
                console.log("instance:",instance)
                instance.show=true
                instance.text=opts.text||""
                instance.url=opts.url||""
            },
            hide(){
                instance.show=false
                instance=null
            }
        }
        if(!Vue.$prevObj){
            Vue.$prevObj=prevObj
        }
        Vue.prototype.$prevImg = prevObj
    }

}