<template>
    <div id='head' v-elevation="elevation" @mouseenter="elevation=5" @mouseleave = "elevation=1">
        <div class="logo">
            <img height = "30px" src="@/assets/images/root.png" alt="">
        </div>
        <div style = 'display: flex;position: sticky' v-if = '$store.state.width > 765'>
            <div class = 'tabbarTitle handle'  @click = 'linkto("/index")'>
              <i class="fa-solid fa-house-user" style = 'color:#8e44ad'></i>
                主页 &nbsp;
            </div>
            <mu-menu placement="bottom" open-on-hover  popover-class = 'tabbarList'>
                <div class = 'tabbarTitle'>
                  <i class="fa-solid fa-cube" style = 'color:#27ae60'></i>
                    导航&nbsp;
                </div>
                <mu-list slot="content" >
                    <mu-list-item button>
                        <mu-list-item-action>
                          <i class="fa-solid fa-tags" style = 'color:#27ae60' ></i>
                        </mu-list-item-action>
                        <mu-list-item-title>关于</mu-list-item-title>
                    </mu-list-item>
                    <mu-list-item button>
                        <mu-list-item-action>
                            <i class="fa-solid fa-tags" style = 'color:#27ae60' ></i>
                        </mu-list-item-action>
                        <mu-list-item-title>剥壳</mu-list-item-title>
                    </mu-list-item>
                    <mu-list-item button>
                        <mu-list-item-title>Send fee</mu-list-item-title>
                    </mu-list-item>
                    <mu-list-item button>
                        <mu-list-item-title>Setti</mu-list-item-title>
                    </mu-list-item>
                    <mu-list-item button>
                        <mu-list-item-title>Help</mu-list-item-title>
                    </mu-list-item>
                    <mu-list-item button>
                        <mu-list-item-title>Sign out</mu-list-item-title>
                    </mu-list-item>
                </mu-list>
            </mu-menu>
            <mu-menu placement="bottom"   open-on-hover  popover-class = 'tabbarList' >
                <div class = 'tabbarTitle'>
                  <i class="fa-solid fa-ellipsis-vertical" style = 'color:#27ae60'></i>
                    更多&nbsp;
                </div>
                <mu-list slot="content" >
                    <mu-list-item button @click = "linkto('/resize-box')">
                        <mu-list-item-action>
                            <i class="fa-solid fa-tags" style = 'color:#27ae60' ></i>
                        </mu-list-item-action>
                        <mu-list-item-title>flex demo</mu-list-item-title>
                    </mu-list-item>
                    <mu-list-item button @click = 'linkto("/game")'>
                        <mu-list-item-action>
                            <i class="fa-solid fa-tags" style = 'color:#27ae60' ></i>
                        </mu-list-item-action>
                        <mu-list-item-title>小游戏</mu-list-item-title>
                    </mu-list-item>

                </mu-list>
            </mu-menu>
        </div>
        <mu-menu placement="bottom"   popover-class = 'tabbarList' v-else>
            <div class = 'tabbarTitle'>
              <i class="fa-solid fa-bars" style = 'color:inherit' ></i>
            </div>
            <mu-list toggle-nested slot="content" toggle-nested-type = "popover">
                <mu-list-item button :ripple="false" @click = 'linkto("/index")'>
                    <mu-list-item-action>
                        <i class="fa-solid fa-house-user" style = 'color:#8e44ad'></i>
                    </mu-list-item-action>
                    <mu-list-item-title>主页</mu-list-item-title>
                </mu-list-item>
                <mu-list-item button :ripple="false" nested :open="open === 'drafts'" @toggle-nested="open = arguments[0] ? 'drafts' : ''">
                    <mu-list-item-action>
                        <i class="fa-solid fa-cube" style = 'color:#27ae60'></i>
                    </mu-list-item-action>
                    <mu-list-item-title>导航</mu-list-item-title>

                    <mu-list-item button :ripple="false" slot="nested" @click = "linkto('/resize-box')">
                        <mu-list-item-action>
                            <i class="fa-solid fa-tags" style = 'color:#27ae60' ></i>
                        </mu-list-item-action>
                        <mu-list-item-title>flex demo</mu-list-item-title>
                    </mu-list-item>
                    <mu-list-item button :ripple="false" slot="nested" @click = 'linkto("/game")'>
                        <mu-list-item-action>
                            <i class="fa-solid fa-tags" style = 'color:#27ae60' ></i>
                        </mu-list-item-action>
                        <mu-list-item-title >小游戏</mu-list-item-title>
                    </mu-list-item>
                </mu-list-item>
            </mu-list>

        </mu-menu>

    </div>
</template>

<script>
export default {
    name: 'BlogHead',
    data:function (){
        return {
            elevation:1,
            open:'send'
        }
    },
     mounted() {


    },
    methods:{
        linkto(url){
            this.$router.push({path:url,query:{
				isStudent:true
			}})
        },
        toHome(){
            this.$router.push('/index')
        },
        toGame(){
            this.$router.push('/game')
        }
    }
}
</script>
<style scoped lang="less">
    #head{
        position: sticky;
        top:0;
        height: 55px;
        margin-bottom: 10px;
        margin-left: -15px;
        max-width: 1200px;
        width:calc(100% + 30px);
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        background: var(--head-bg) ;
        backdrop-filter: saturate(200%) blur(20px);
        z-index: 1600;
        display: flex;
        align-items: center;
        padding:0 15px;
        justify-content: space-between;
        .logo{
            padding:0 20px;
        }
        .tabbarTitle{
            font-size: 16px;
            margin:0 5px;
            line-height: 50px;
            text-align: center;
            font-weight:600;
            &:hover{
                color:var(--color-list-hl);
            }
        }

    }

    @media screen and (max-width:765px) {
        #head{
            border-radius: unset;

        }
    }
</style>
<style lang="less">
.tabbarList{
    border-radius: 4px!important;
     .mu-item-action{
        min-width: 24px!important;
    }
}
</style>