import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
const store  = new Vuex.Store({
    state:{
        width:1920,
        theme:"light"
    },
    mutations:{
        widthResize(state,width){
            state.width = width
        },
        setTheme(state,theme){
            state.theme = theme
        }
    }
})
export default store