<template>
    <div ref = 'resizebox' class = 'wbox'>
        <div class = 'nbox' v-for = 'i in list' :key = 'i' :style = "{width:width}">
            <slot name = 'item' :item="i">
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "ResizeBox",
    props:{
        itemWidth:{
            type:Number,
            default:100,
        },
        list:{
            type:Array,
            default:function (){
                return []
            }
        },
        lackCenter:{
            type:Boolean,
            default:false
        },
        indentWidth:{
            type:[Number,String],
            default:20
        }
    },
    data:function () {
        return {
            timer:null,
            width:0
        }
    },
    beforeMount() {
        this.width = this.itemWidth+'px'
    },
    mounted() {
        this.$nextTick(()=> {
            window.addEventListener('resize', this.resizeHandler)
            this.createTimer()
        })
    },
    watch:{
        'list.length':function(){
            this.createTimer()
        },
        lackCenter:function () {
            this.createTimer()
        }
    },
    methods:{

        createTimer(){
            this.timer = setTimeout(()=> {
                if(this.$refs.resizebox){
                    let width = this.$refs.resizebox.clientWidth-1
                    let len = Math.floor(width / this.itemWidth)
                    if(len>this.list.length){
                        if(this.lackCenter){
                            len = this.list.length;
                            this.width = `calc(100% / ${len})`
                        }else{
                            this.width = this.itemWidth+'px'
                        }
                    }else{
                        this.width = `calc(100% / ${len})`

                    }
                }
                this.timer = null
            },10)
        },
        resizeHandler(){
            if(this.timer){
                clearTimeout(this.timer)
                this.createTimer()
            }else{
                this.createTimer()
            }

        }
    },
    beforeDestroy() {
        window.removeEventListener('resize',this.resizeHandler)
    }
}
</script>

<style scoped lang = 'less'>
.wbox{
    display: flex;
    flex-wrap: wrap;
    // height:100px;
    .nbox{
        transition: width .1s;
    }
}
</style>