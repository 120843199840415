<template>
    <div class="backup" @click='backTop' :style="{
	  right:right+'px',
	  bottom:bottom+'px',
	  fontSize:size+'px',
	  color:color
  }">
        <mu-scale-transition>
            <div v-show="show" class = 'liu'>
                    <i class="fa-solid fa-circle-up"></i>
            </div>
        </mu-scale-transition>
    </div>
</template>

<script>
export default {
    name: 'BackTop',
    props: {
        emitId:{
            type:String,
            default:''
        },
        scrollHeight:{
            type:Number,
            default:300
        },
        right: {
            type: Number,
            default: 10
        },
        bottom: {
            type: Number,
            default: 30
        },
        size: {
            type: Number,
            default: 30
        },
        color: {
            type: String,
            default: "#000"
        }
    },
    data: function() {
        return {
            show: false,
            ele:'',
            scrollTop:0
        }
    },
    // vue的两个生命钩子，这里不多解释。
    // window对象，所有浏览器都支持window对象。它表示浏览器窗口，监听滚动事件
    mounted() {
        if(this.emitId){
            this.ele = document.getElementById(this.emitId)
        }
        (this.ele||window).addEventListener('scroll', this.scrollToTop)
    },
    destroyed() {
        (this.ele||window).removeEventListener('scroll', this.scrollToTop)
        this.ele = ''
    },


    methods: {
        // 点击图片回到顶部方法，加计时器是为了过渡顺滑
        backTop() {
            let timer = setInterval(() => {
                let ispeed = Math.floor((-this.scrollTop / 5));
                (this.ele||document.documentElement).scrollTop = (this.ele||document.body).scrollTop = this.scrollTop + ispeed
                if (this.scrollTop === 0) {
                    clearInterval(timer)
                }
            }, 16)
        },

        // 为了计算距离顶部的高度，当高度大于 scrollHeight 显示回顶部图标，小于 scrollHeight 则隐藏
        scrollToTop() {
            const that = this

            let scrollTop = (this.ele||window).pageYOffset || (this.ele||document.documentElement).scrollTop || (this.ele||document.body).scrollTop
            that.scrollTop = scrollTop
            if (that.scrollTop > this.scrollHeight) {
                that.show = true
            } else {
                that.show = false
            }
        }
    }
}
</script>
<style lang="less" scoped>
.backup {
    position: fixed;
    cursor: pointer;
    z-index: 1600
}
</style>
